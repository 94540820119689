<template>
  <div
    v-if="metrics.length > 0"
    class="flex q-pt-none q-gutter-x-lg items-center"
    :class="'q-gutter-x-' + spacing"
  >
    <div v-for="(metric, i) in metrics" :key="'MetricsList_' + key + '_' + i">
      <Label :text="metric.label" underlined />
      <h4 v-if="!small" v-html="metric.value" />
      <h6 v-else v-html="metric.value" />
    </div>
  </div>
</template>

<script>
import Label from "@/components/UI/Label";

export default {
  name: "MetricsList",
  components: { Label },
  props: {
    metrics: {
      type: Array,
      required: true
    },
    small: {
      type: Boolean,
      default: false
    },
    spacing: {
      type: String,
      default: "lg"
    }
  },
  data() {
    return {
      key: Math.floor(Math.random() * 10000)
    };
  },
  methods: {}
};
</script>

<style scoped lang="scss"></style>
