<template>
  <WidgetContainer
    :loading="loading"
    width="4"
    header="Accounts Overview"
    show-navigation-bar
    navigation-label="Go to Accounts"
    :navigation-action="
      () => $router.push({ name: 'AdvertiserAccountsOverview' })
    "
  >
    <template v-slot>
      <MetricsList
        v-if="overviewMetrics.length > 0"
        :metrics="overviewMetrics"
        class="q-pl-md q-pb-md"
      />

      <DataTableSimple
        table-key="AdvertiserAccountsOverviewWidget_DataTable"
        row-key="id"
        :columns="dataTableColumns"
        :rows="dataTableRows"
        :pagination="{
          sortBy: 'products_active_total',
          descending: true,
          page: 1,
          rowsPerPage: 5
        }"
      />
    </template>
  </WidgetContainer>
</template>

<script>
import DataTableSimple from "@/components/UI/DataTableSimple";
import MetricsList from "@/components/UI/MetricsList";
import WidgetContainer from "@/components/UI/Widgets/WidgetContainer";
import StatusBadge from "@/components/UI/StatusBadge";

export default {
  name: "AdvertiserAccountsOverviewWidget",
  components: { WidgetContainer, MetricsList, DataTableSimple },
  props: {},
  data() {
    return {
      loading: false,
      overviewMetrics: [],
      dataTableRows: [],
      dataTableColumns: [
        {
          name: "name",
          label: "Account",
          field: row =>
            "<p class='ellipsis'>" +
            row.name +
            "</p>" +
            "<p class='--subtext'>" +
            row.id +
            "</p>",
          style: "max-width: 10vw; min-width: 140px"
        },
        {
          name: "active",
          label: "Status",
          hideField: true,
          isComponentField: true,
          field: row => ({
            component: StatusBadge,
            componentProperties: {
              status: row.status,
              tooltipInnerHtml: row.status_tooltip,
              iconOnly: true
            },
            componentBody: ""
          })
        },
        {
          name: "connected_inventory_sources",
          label: "Sources",
          field: "connected_inventory_sources",
          sort: (a, b) => this.sortInteger(a, b)
        },
        {
          name: "products_active_total",
          label: this.$store.state.productTypeConfigs.typeFormatted + "s",
          field: row =>
            typeof row.products_active_total === "undefined"
              ? 0
              : row.products_active_total,
          format: val => this.numberFormatter(val),
          sort: (a, b) => this.sortInteger(a, b)
        }
      ]
    };
  },
  mounted() {
    this.populateWidget();
  },
  methods: {
    populateWidget() {
      this.loading = true;

      let countsByMetric = {
        activeAccounts: 0,
        activeSources: 0,
        activeProducts: 0
      };
      let rowsByAccountId = {};

      this.$store.dispatch("advertiserAccounts/GET_REQUEST", {
        params: {}
      }).then(accounts => {
        if (accounts.length > 0) {
          accounts.forEach(advertiserAccount => {
            rowsByAccountId[advertiserAccount.id] = advertiserAccount;
            countsByMetric.activeAccounts += +advertiserAccount.active;
            countsByMetric.activeSources += +advertiserAccount.active_inventory_sources;
            countsByMetric.activeProducts += +advertiserAccount.products_active_total;
          });

          this.overviewMetrics = [
            {
              label: "Active Accounts",
              value: this.numberFormatter(countsByMetric.activeAccounts)
            },
            {
              label: "Active Sources",
              value: this.numberFormatter(countsByMetric.activeSources)
            },
            {
              label: "Active " + this.$store.state.productTypeConfigs.typeFormatted + "s",
              value: this.numberFormatter(countsByMetric.activeProducts)
            }
          ];

          this.dataTableRows = accounts;
        }

        this.loading = false;
      });
    }
  }
};
</script>

<style scoped lang="scss"></style>
