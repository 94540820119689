<template>
  <span>
    <q-badge
      v-if="!iconOnly"
      :color="statusConfigs.bgColor"
      :text-color="statusConfigs.textColor"
      class="text-uppercase text-weight-medium q-pa-xs flex items-center justify-between"
    >
      <q-icon
        v-if="includeIcon"
        :name="statusConfigs.icon"
        size="13px"
        class="q-mr-xs --icon-filled"
      />
      <span>{{
        customLabel.length > 0 ? customLabel : statusConfigs.label
      }}</span>
    </q-badge>
    <q-icon
      v-else
      :name="statusConfigs.icon"
      :color="statusConfigs.textColor"
      :size="iconOnlySize"
      class="--icon-filled"
    />

    <q-tooltip
      v-if="!hideTooltip && (iconOnly || tooltipInnerHtml.length > 0)"
      anchor="center right"
      self="center left"
    >
      <span
        v-html="
          tooltipInnerHtml.length > 0 ? tooltipInnerHtml : statusConfigs.label
        "
      />
    </q-tooltip>

    <span
      v-if="customAppendHtml.length > 0"
      class="q-ml-xs"
      v-html="customAppendHtml"
    />
  </span>
</template>

<script>
export default {
  name: "StatusBadge",
  props: {
    status: {
      type: String,
      required: true
    },
    includeIcon: {
      type: Boolean,
      default: false
    },
    iconOnly: {
      type: Boolean,
      default: false
    },
    iconOnlySize: {
      type: String,
      default: "21px"
    },
    customLabel: {
      type: String,
      default: ""
    },
    tooltipInnerHtml: {
      type: String,
      default: ""
    },
    hideTooltip: {
      type: Boolean,
      default: false
    },
    customAppendHtml: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      statusConfigsMap: {
        active: {
          bgColor: "positive-light",
          textColor: "positive",
          label: "Active",
          icon: "sym_r_play_circle"
        },
        deactivated: {
          bgColor: "negative-light",
          textColor: "negative",
          label: "Deactivated",
          icon: "sym_r_stop_circle"
        },
        paused: {
          bgColor: "gray-light",
          textColor: "subtle",
          label: "Paused",
          icon: "sym_r_pause_circle"
        },
        disabled: {
          bgColor: "gray-light",
          textColor: "subtle",
          label: "Disabled",
          icon: "sym_r_do_not_disturb_on"
        },
        success: {
          bgColor: "positive-light",
          textColor: "positive",
          label: "Success",
          icon: "sym_r_check_circle"
        },
        error: {
          bgColor: "negative-light",
          textColor: "negative",
          label: "Error",
          icon: "sym_r_error"
        },
        warning: {
          bgColor: "warning-light",
          textColor: "warning",
          label: "Warning",
          icon: "sym_r_warning"
        },
        running: {
          bgColor: "gray-light",
          textColor: "subtle",
          label: "Running",
          icon: "sym_r_sync"
        }
      }
    };
  },
  computed: {
    statusConfigs() {
      return this.statusConfigsMap[this.status];
    }
  }
};
</script>

<style scoped lang="scss"></style>
